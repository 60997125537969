import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, Button, Box, Grid, Fade, Slide, AppBar, Toolbar, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface StoryPage {
  page_number: number;
  content: string;
  page_image_url: string;
}

interface Story {
  title: string;
  title_image_url: string;
  pages: StoryPage[];
}

const DisplayStory: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const story: Story = location.state;

  const [currentPage, setCurrentPage] = useState(0);
  const [transitionIn, setTransitionIn] = useState(true);
  const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('right');

  if (!story) {
    return (
      <Container>
        <Typography variant="h4" component="h1" gutterBottom>
          No story available
        </Typography>
      </Container>
    );
  }

  const handleNextPage = () => {
    if (currentPage < story.pages.length) {
      setTransitionIn(false);
      setSlideDirection('left');
      setTimeout(() => {
        setCurrentPage(currentPage + 1);
        setTransitionIn(true);
      }, 500);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setTransitionIn(false);
      setSlideDirection('right');
      setTimeout(() => {
        setCurrentPage(currentPage - 1);
        setTransitionIn(true);
      }, 500);
    }
  };

  const { title, title_image_url, pages: storyPages } = story;

  return (
    <Container>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate('/')}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Story Viewer
          </Typography>
        </Toolbar>
      </AppBar>
      <Box textAlign="center" my={4}>
        <Fade in={transitionIn} timeout={500}>
          <Box minHeight="400px">
            {currentPage === 0 ? (
              <>
                <Typography variant="h2" component="h1" gutterBottom style={{ fontFamily: 'Cursive', color: '#4A90E2' }}>
                  {title}
                </Typography>
                <img src={title_image_url} alt="Title" style={{ width: '100%', maxHeight: '100%', objectFit: 'cover', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)' }} />
              </>
            ) : (
              <Slide direction={slideDirection} in={transitionIn} mountOnEnter unmountOnExit>
                <Box>
                  <Typography variant="body1" component="p" gutterBottom style={{ fontFamily: 'Comic Sans MS, Comic Sans, cursive', fontSize: '1.2rem', color: '#333' }}>
                    {storyPages[currentPage - 1].content}
                  </Typography>
                  <img src={storyPages[currentPage - 1].page_image_url} alt={`Page ${storyPages[currentPage - 1].page_number}`} style={{ width: '100%', maxHeight: '100%', objectFit: 'cover', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)' }} />
                </Box>
              </Slide>
            )}
          </Box>
        </Fade>
      </Box>
      <Box mt={4} px={2}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <Button variant="contained" color="primary" onClick={handlePreviousPage} disabled={currentPage === 0}>
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleNextPage} disabled={currentPage === storyPages.length}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default DisplayStory;

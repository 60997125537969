import React from 'react';

const LoadingScreen: React.FC = () => {
  return (
    <div className="loading-screen">
      <img
        src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbXhrN2s2Y3BuMzl4cTN4N3hwZmh3d2J4czR5cjdlMm5rOG81NmlpcCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/EoEBPJNm72STTsfNTx/giphy.gif"
        alt="Loading animation"
        style={{ width: '300px', height: '300px' }}
      />
      <p>Loading your story...</p>
    </div>
  );
};

export default LoadingScreen;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import LoadingScreen from './LoadingScreen';
import { Button, Card, CardContent, CardHeader, Typography, Container, Grid, Slider, Box } from '@mui/material';
import axios from 'axios';

const options = {
  settings: [
    { label: "Space", emoji: "🚀" },
    { label: "Jungle", emoji: "🌴" },
    { label: "Underwater", emoji: "🌊" },
    { label: "Magical Kingdom", emoji: "🏰" },
    { label: "City", emoji: "🏙️" },
    { label: "Farm", emoji: "🌾" },
    { label: "Fairy Tale Forest", emoji: "🌳" }
  ],
  characters: [
    { label: "Animals", emoji: "🐾" },
    { label: "Superheroes", emoji: "🦸" },
    { label: "Robots", emoji: "🤖" },
    { label: "Fairy Tale Creatures", emoji: "🧚" },
    { label: "Dinosaurs", emoji: "🦕" },
    { label: "Pirates", emoji: "🏴‍☠️" },
    { label: "Aliens", emoji: "👽" }
  ],
  plots: [
    { label: "Adventure", emoji: "🗺️" },
    { label: "Mystery", emoji: "🕵️" },
    { label: "Educational", emoji: "📘" },
    { label: "Friendship", emoji: "🤝" },
    { label: "Helping Others", emoji: "🤲" },
    { label: "Imagination", emoji: "💭" },
    { label: "Discovery", emoji: "🔍" }
  ],
  themes: [
    { label: "Teamwork", emoji: "🤝" },
    { label: "Kindness", emoji: "❤️" },
    { label: "Courage", emoji: "🦁" },
    { label: "Creativity", emoji: "🎨" },
    { label: "Problem-solving", emoji: "🧠" },
    { label: "Environmental Awareness", emoji: "🌍" },
    { label: "Respect", emoji: "🙏" }
  ],
  endings: [
    { label: "Happy", emoji: "😊" },
    { label: "Surprising", emoji: "😲" },
    { label: "Moral Lesson", emoji: "📜" },
    { label: "Bittersweet", emoji: "😢" },
    { label: "Open-Ended", emoji: "❓" },
    { label: "Triumphant", emoji: "🏆" },
    { label: "Reflective", emoji: "🤔" }
  ],
  tones: [
    { label: "Light-Hearted", emoji: "😄" },
    { label: "Serious", emoji: "😐" },
    { label: "Humorous", emoji: "😂" },
    { label: "Inspiring", emoji: "✨" },
    { label: "Mystical", emoji: "🔮" },
    { label: "Action-Packed", emoji: "⚔️" },
    { label: "Calm", emoji: "😌" }
  ]
};

const CreateStory: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [settings, setSettings] = useState(0);
  const [characters, setCharacters] = useState(0);
  const [plots, setPlots] = useState(0);
  const [themes, setThemes] = useState(0);
  const [endings, setEndings] = useState(0);
  const [tones, setTones] = useState(0);
  const navigate = useNavigate();

  const createNewStory = async () => {
    setIsLoading(true);
    setMessage('The story is being created at the moment.');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/stories`, {
        setting: options.settings[settings].label,
        characters: options.characters[characters].label,
        plot: options.plots[plots].label,
        theme: options.themes[themes].label,
        ending: options.endings[endings].label,
        tone: options.tones[tones].label,
      });
      setMessage('Story created successfully!');
      console.log('API response:', response.data);
      navigate('/story', { state: response.data });
    } catch (error) {
      setMessage('Failed to create story.');
      console.error('Error creating story:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const marks = (values: { label: string, emoji: string }[]) => values.map((value, index) => ({
    value: index,
    label: <span className="slider-emoji">{value.emoji}</span>,
  }));

  return (
    <div className="App">
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <Container>
            <Grid container spacing={3}>
              {[
                { title: "Setting", value: settings, onChange: setSettings, options: options.settings },
                { title: "Character", value: characters, onChange: setCharacters, options: options.characters },
                { title: "Plot", value: plots, onChange: setPlots, options: options.plots },
                { title: "Theme", value: themes, onChange: setThemes, options: options.themes },
                { title: "Ending", value: endings, onChange: setEndings, options: options.endings },
                { title: "Tone", value: tones, onChange: setTones, options: options.tones }
              ].map((slider, index) => (
                <Grid item xs={12} key={index}>
                  <Card variant="outlined">
                    <CardHeader title={slider.title} titleTypographyProps={{ variant: 'h6', style: { fontWeight: 'bold' } }} />
                    <CardContent>
                      <Box paddingX={5}>
                        <Slider
                          value={slider.value}
                          onChange={(e, val) => slider.onChange(val as number)}
                          step={1}
                          marks={marks(slider.options)}
                          min={0}
                          max={slider.options.length - 1}
                          valueLabelDisplay="auto"
                          valueLabelFormat={index => slider.options[index].label}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={createNewStory}
              disabled={isLoading}
              style={{ marginTop: '20px' }}
            >
              Create a New Story
            </Button>
            <Typography variant="body1" style={{ marginTop: '20px' }}>
              {message}
            </Typography>
          </Container>
        </header>
      )}
    </div>
  );
};

export default CreateStory;
